<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout id="components-layout-demo-custom-trigger">
        <Sider />
        <a-layout class="section">
          <a-layout-content
            :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }"
          >
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header
                    style="border-bottom: 1px solid rgb(235, 237, 240)"
                    title="付费升级"
                  >
                    <!-- <template slot="extra">
                      <a-button
                        html-type="submit"
                        style="margin-left: 16px"
                        @click="getExport(1)"
                        size="small"
                      >
                        导出
                      </a-button>
                    </template> -->
                  </a-page-header>
                </div>
                <div ref="form">
                  <a-form
                    :form="form"
                    layout="inline"
                    @submit="handleSubmit"
                    style="padding: 15px 0"
                    ref="form"
                  >
                    <a-form-item label="用户手机号">
                      <a-input
                        v-decorator="[
                          'mobile',
                          { rules: [{ required: false }] },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="付款状态">
                      <a-select
                        placeholder="全部"
                        style="width: 180px"
                        v-decorator="[
                          'is_pay',
                          { rules: [{ required: false }] },
                        ]"
                      >
                        <a-select-option value=""> 全部 </a-select-option>
                        <a-select-option value="1"> 成功 </a-select-option>
                        <a-select-option value="0"> 失败 </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item>
                      <a-button type="primary" html-type="submit">
                        搜索
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
                <a-table
                  :columns="columns"
                  :data-source="upgradeList"
                  :pagination="false"
                  :scroll="{ x: 1000, y: table_h }"
                  :row-key="(record) => record.id"
                  size="middle"
                  ref="tableList"
                >
                  <span slot="avatar" slot-scope="avatar">
                    <img :src="avatar" alt="" />
                  </span>
                  <span
                    slot="before_grade"
                    slot-scope="before_grade"
                  >
                    <span v-if="before_grade == 1">街头艺人</span>
                    <span v-if="before_grade == 2">当红明星</span>
                    <span v-if="before_grade == 3">翻红馆长</span>
                  </span>
                  <span
                    slot="after_grade"
                    slot-scope="after_grade"
                  >
                    <span v-if="after_grade == 1">街头艺人</span>
                    <span v-if="after_grade == 2">当红明星</span>
                    <span v-if="after_grade == 3">翻红馆长</span>
                  </span>
                  <span
                    slot="is_pay"
                    slot-scope="is_pay"
                  >
                    <span v-if="is_pay == 1">成功</span>
                    <span v-if="is_pay == 0">失败</span>
                  </span>
                  <span
                    slot="total_amount"
                    slot-scope="total_amount"
                  >
                    {{total_amount}}元
                  </span>
                  <span slot="pid" slot-scope="pid">
                    {{ pid == 0 ? "--" : pid }}
                  </span>
                </a-table>
                <div ref="pagination">
                  <a-pagination
                    v-if="pageshow"
                    :default-current="currentPage"
                    :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions"
                    show-size-changer
                    show-quick-jumper
                    :total="total_num"
                    :show-total="(total) => `共 ${total} 条`"
                    @showSizeChange="onShowSizeChange"
                    @change="onPageChange"
                  >
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      columns: [
        {
          title: "用户昵称",
          key: "users.nickname",
          dataIndex: "users.nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "users.mobile",
          dataIndex: "users.mobile",
          align: "center",
        },
        {
          title: "邀请人手机号",
          key: "users_parent.mobile",
          dataIndex: "users_parent.mobile",
          align: "center",
        },
        {
          title: "升级前等级",
          key: "before_grade",
          dataIndex: "before_grade",
          scopedSlots: { customRender: "before_grade" },
          align: "center",
        },
        {
          title: "升级后等级",
          key: "after_grade",
          dataIndex: "after_grade",
          scopedSlots: { customRender: "after_grade" },
          align: "center",
        },
        {
          title: "金额",
          key: "total_amount",
          dataIndex: "total_amount",
          scopedSlots: { customRender: "total_amount" },
          align: "center",
        },
        {
          title: "支付状态",
          key: "is_pay",
          dataIndex: "is_pay",
          scopedSlots: { customRender: "is_pay" },
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 20,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      upgradeList: [],
      confirmLoading: false,
    };
  },
  components: {
    Header,
    Sider,
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/paidUpgradeOrder/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var upgradeList = res.data.data.list;
            // for (var i = 0; i < upgradeList.length; i++) {
            //   upgradeList[i].btns = ["认证信息"];
            // }
            that.changeTableH();
            that.upgradeList = upgradeList;
            that.total_num = res.data.data.hsaMore
              ? res.data.data.page * data.limit + 1
              : res.data.data.page * data.limit;
            that.currentPage = res.data.data.page;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
      if (this.export == 1) {
        var myObj = {
          method: "get",
          url: "/admin/homeUsers/export",
          fileName: "用户列表.csv",
        };
        this.$exportMethod(myObj);
      }
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      this.export = 0;
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
}

.ant-table-wrapper {
  flex: 1;
}

.platInfo_all {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.screenshot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.screenshot img {
  width: 120px;
}

.platInfo_info {
  line-height: 30px;
  width: 70%;
}

.platInfo_info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px 0;
}

.vue-directive-image-previewer {
  z-index: 10000 !important;
}
</style>